import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import ReviewsSlider from '../components/ReviewsSlider'
import Faq from '../components/Faq/index'
import PageTitle from '../components/Blocks/PageTitle'

const PageTemplate = ({ title, content, faq, reviews }) => {
  return (
    <div className="w-full">
      <PageTitle title={title} />
      <div
        className={'transition-all page-content'}
        dangerouslySetInnerHTML={{ __html: content }}
      />
      <ReviewsSlider />
      <div aria-hidden="true" class="wp-block-spacer wp-block-spacer-reviews"></div>
      {faq?.title && <Faq title={faq?.title} items={faq?.faqs} />}
    </div>
  )
}

const Reviews = ({ data, pageContext }) => {
  const { wpPage: page } = data
  return (
    <Layout pageContext={pageContext}>
      <Seo seo={page.seo} />
      <PageTemplate title={page.title} content={page.content} faq={page.faq} />
    </Layout>
  )
}

export default Reviews

export const pageQuery = graphql`
  query Reviews($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      seo {
        fullHead
        schema {
          raw
        }
      }
      faq {
        title
        faqs {
          body
          title
        }
      }
    }
  }
`
